.FarmSell {
  padding-top: 15px;
}

.FarmSell form {
  padding-bottom: 15px;
}

.FarmSell form text {
  font-size: 24px;
}

.FarmSell form textarea {
  height: 200px;
  font-size: 14px;
}

.FarmSell .ck-file-dialog-button {
  display: none;
  visibility: hidden;
}

.FarmSell .txt-center {
  text-align: center;
}

.FarmSell .img-center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.FarmSell .img-max-h-300 {
  max-height: 300px;
}

.FarmSell .photo-delete-btn {
  margin-top: -55px;
}

.FarmSell .wrapperClassName {
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.FarmSell .DraftEditor-editorContainer {
  padding-left: 5px;
}
