.AboutUsEdit form {
  padding-bottom: 15px;
}

.AboutUsEdit form text {
  font-size: 24px;
}

.AboutUsEdit form textarea {
  height: 200px;
  font-size: 14px;
}
