.ContactSeller .seller-section {
  background-color: #ddd;
  padding: 15px;
  margin-bottom: 20px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
}

.ContactSeller .section-title {
  font-weight: bold;
  font-size: 22px;
}

.ContactSeller .contact-seller-name {
  font-weight: bold;
}

.ContactSeller .contact-seller,
.contact-seller:hover,
.contact-seller:active,
.contact-seller:focus {
  font-size: 20px;
  padding: 10px;
  width: 100%;
  border-radius: 4px;
}

.ContactSeller .contact-legal-disclaimer {
  margin: 0 auto;
  text-align: center;
  font-size: 12px;
  padding-top: 5px;
  max-width: 350px;
}

