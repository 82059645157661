.Farm .card {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

.Farm .card-header {
  padding: 0.5rem 0.5rem;
  margin-bottom: 0;
  color: inherit;
  background-color: rgba(0, 0, 0, 0.01);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.Farm .card-footer {
  padding: 0.5rem 0.5rem;
  background-color: rgba(0, 0, 0, 0.01);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.Farm .card-body {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1.25rem;
}

.Farm .card-title {
  margin-bottom: 0.75rem;
}

.Farm .card-col {
  padding-bottom: 10px;
  padding-right: 10px;
  padding-left: 10px;
}

.Farm .float-right {
  float: right !important;
}

.Farm .pageImage {
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-top: 15px;
}

.Farm .font-green {
  color: #71b831;
  font-size: 20px;
  font-weight: 600;
  margin-right: 10px;
}

.Farm .bold {
  font-weight: 600;
}

.Farm .txt-center {
  text-align: center;
}

.Farm .img-center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.Farm .card-img-top {
  width: auto;
  background-repeat: no-repeat;
  background-size: contain;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

figure table {
  table-layout: auto;
  width: 100%;
  border: 0mm;
  border-spacing: 0px;
}

.Farm .Countdown {
  color: #fc4a1a;
}

.Farm .seller-section {
  background-color: #ddd;
  padding: 15px;
  margin-bottom: 20px;
}

.Farm .section-title {
  font-weight: bold;
  font-size: 22px;
}

.Farm .contact-seller-name {
  font-weight: bold;
}

.Farm .contact-seller,
.contact-seller:hover,
.contact-seller:active,
.contact-seller:focus {
  font-size: 20px;
  padding: 10px;
  width: 100%;
  border-radius: 4px;
}

.Farm .contact-legal-disclaimer {
  margin: 0 auto;
  text-align: center;
  font-size: 12px;
  padding-top: 5px;
  max-width: 350px;
}

.Farm .mapouter {
  position: relative;
  text-align: right;
  width: 100% !important;
  height: 500 !important;
}
.Farm .gmap_canvas {
  overflow: hidden;
  background: none !important;
  width: 100% !important;
  height: 500 !important;
}

.Farm .img-fluid {
  max-height: 800px;
  width: auto;
}

.Farm .val-col-padding {
  padding-top: 4px;
  padding-bottom: 4px;
}
