.FarmRent {
  padding-top: 15px;
}

.FarmRent form {
  padding-bottom: 15px;
}

.FarmRent form text {
  font-size: 24px;
}

.FarmRent form textarea {
  height: 200px;
  font-size: 14px;
}

.FarmRent .ck-file-dialog-button {
  display: none;
  visibility: hidden;
}

.FarmRent .txt-center {
  text-align: center;
}

.FarmRent .img-center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.FarmRent .img-max-h-300 {
  max-height: 300px;
}

.FarmRent .photo-delete-btn {
  margin-top: -55px;
}

.FarmRent .wrapperClassName {
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.FarmRent .DraftEditor-editorContainer {
  padding-left: 5px;
}
