.UserEdit {
  padding-top: 15px;
}

.UserEdit form {
  padding-bottom: 15px;
}

.UserEdit form text {
  font-size: 24px;
}

.UserEdit form textarea {
  height: 200px;
  font-size: 14px;
}

.UserEdit .ck-file-dialog-button {
  display: none;
  visibility: hidden;
}
