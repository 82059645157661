.Dashboard {
  padding-top: 15px;
}

.Dashboard .sub-header {
  padding: 0.75em 16px;
  border: 1px solid #33363b !important;
  border-radius: 16px;
  background: #eaeaea !important;
  color: #33363b;
}

.Dashboard .sub-header-nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.Dashboard .sub-header > a {
  color: #33363b;
}

.Dashboard a.sub-header-nav-link[href="/new/listing"] {
  border: 1px solid #025326;
  border-radius: 0.5rem;
  color: #fff !important;
  background: #28a745;
  font-weight: bold;
  text-align: center !important;
  padding: 0.5rem 1.25rem !important;
  margin-right: 10px;
  margin-left: 40px;
  text-decoration: none;
}
