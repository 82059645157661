.Seller .lander {
  padding: 0px 0;
  text-align: center;
}

.Seller .lander h1 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}

.Seller .lander p {
  color: #999;
}

.Seller {
  padding-top: 15px;
}

.Seller .card {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

.Seller .card-header {
  font-size: 0.9rem;
  font-weight: 600;
  padding: 0.5rem 0.5rem;
  margin-bottom: 0;
  color: inherit;
  background-color: rgba(0, 0, 0, 0.01);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.Seller .card-body {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 0.5rem;
  font-size: 0.8rem;
}

.Seller .card-footer {
  padding: 0.5rem 0.5rem;
  background-color: rgba(0, 0, 0, 0.01);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.Seller .card-title {
  font-size: 0.8rem;
  font-weight: 600;
  margin-bottom: 0.75rem;
}

.Seller .card-col {
  padding-bottom: 30px;
  padding-right: 15px;
  padding-left: 15px;
}

.Seller .card-col a {
  color: black;
  text-decoration: none;
}

.Seller .card-col a:visited {
  text-decoration: none;
}

.Seller .card-col a:hover {
  text-decoration: none;
}

.Seller .float-right {
  float: right !important;
}

.Seller .pageImage {
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-top: 15px;
}

.Seller .bold {
  font-weight: 600;
}

.Seller .buttonSpace {
  margin-left: 2px;
}

.Seller .card-img-top {
  width: 100%;
  height: 100%;
  max-height: 255px;
  background-repeat: no-repeat;
  background-size: contain;
  display: block;
  margin-left: auto;
  margin-right: auto;
  object-fit: contain;
}

.Seller .card-img-top-sm {
  background-color: #eaeaea !important;
}

@media (min-width: 576px) {
  .container .Seller .card-img-top-sm {
    max-height: 75px;
  }
}
@media (min-width: 768px) {
  .container .Seller .card-img-top-sm {
    max-height: 75px;
  }
}
@media (min-width: 992px) {
  .container .Seller .card-img-top-sm {
    max-height: 105px;
  }
}
@media (min-width: 1200px) {
  .container .Seller .card-img-top-sm {
    max-height: 125px;
  }
}
@media (min-width: 1540px) {
  .container .Seller .card-img-top-sm {
    max-height: 170px;
  }
}

.Seller .card-footer-btn {
  margin-left: 2px;
}

.Seller .property-card--address-pin-and-address {
  display: flex;
  align-items: stretch;
  flex-grow: 1;
  vertical-align: middle;
}

.Seller .property-card--address-pin {
  background: url(/images/property-card--address-pin.png) no-repeat left center;
  width: 23px;
  margin-bottom: 15px;
}

.Seller .property-card--address {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
