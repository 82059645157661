.Agents {
  padding-top: 15px;
}

.Agents .card {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

.Agents .card-header {
  font-size: 0.9rem;
  font-weight: 600;
  padding: 0.5rem 0.5rem;
  margin-bottom: 0;
  color: inherit;
  background-color: rgba(0, 0, 0, 0.01);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.Agents .card-body {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 0.5rem;
  font-size: 0.8rem;
}

.Agents .card-footer {
  padding: 0.5rem 0.5rem;
  background-color: rgba(0, 0, 0, 0.01);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.Agents .card-title {
  font-size: 0.8rem;
  font-weight: 600;
  margin-bottom: 0.75rem;
}

.Agents .card-col {
  padding-bottom: 30px;
  padding-right: 15px;
  padding-left: 15px;
}

.Agents .card-col a {
  color: black;
  text-decoration: none;
}

.Agents .card-col a:visited {
  text-decoration: none;
}

.Agents .card-col a:hover {
  text-decoration: none;
}

.Agents .float-right {
  float: right !important;
}

.Agents .pageImage {
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-top: 15px;
}

.Agents .bold {
  font-weight: 600;
}

.Agents .buttonSpace {
  margin-left: 2px;
}

.Agents .card-img-top {
  width: auto;
  max-height: 255px;
  background-repeat: no-repeat;
  background-size: contain;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.Agents .card-footer-btn {
  margin-left: 2px;
}

.Agents .property-card--address-pin-and-address {
  display: flex;
  align-items: stretch;
  flex-grow: 1;
  vertical-align: middle;
}

.Agents .property-card--address-pin {
  background: url(/images/property-card--address-pin.png) no-repeat left center;
  width: 23px;
  margin-bottom: 15px;
}

.Agents .property-card--address {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
