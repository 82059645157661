.NewListing .lander {
  padding: 0px 0;
  text-align: center;
}

.NewListing .lander h1 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}

.NewListing .lander p {
  color: #999;
}

.NewListing {
  padding-top: 15px;
}

.NewListing a.nav-link {
  font-weight: 600 !important;
}

.NewListing .sub-title {
  font-weight: 600;
  color: #33363b;
}

.NewListing .float-right {
  float: right !important;
}

.NewListing .pageImage {
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-top: 15px;
}

.NewListing .bold {
  font-weight: 600;
}

.NewListing .card-col {
  padding-bottom: 30px;
}

.NewListing .card-header-max {
  color: #fff;
  background-color: #28a745;
}

.NewListing .card-header-plus {
  color: #fff;
  background-color: #007bff;
}

.NewListing .card-header-free {
  color: #fff;
  background-color: #545b62;
}

.NewListing .option-box {
  display: inline-block;
  margin: 10px;
  border: 1px solid #eee;
  box-shadow: 0 2px 2px #ccc;
  width: 200px;
  padding: 20px;
}
