.Home .lander {
  padding: 0px 0;
  text-align: center;
}

.Home .lander h1 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}

.Home .lander p {
  color: #999;
}

.Home {
  padding-top: 15px;
}

.Home .float-right {
  float: right !important;
}

.Home .pageImage {
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-top: 15px;
}

.Home .bold {
  font-weight: 600;
}
