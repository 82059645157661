body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
}

h1,
.h1 {
  font-size: 2.5rem;
}

h2,
.h2 {
  font-size: 2.25rem;
}

h3,
.h3 {
  font-size: 2rem;
}

h4,
.h4 {
  font-size: 1.75rem;
}

h5,
.h5 {
  font-size: 1.5rem;
}

h6,
.h6 {
  font-size: 1.25rem;
}

select.form-control,
textarea.form-control,
input.form-control {
  font-size: 14px;
}
input[type="file"] {
  width: 100%;
}
label {
  font-weight: 600;
}
/*Content*/
.container h4 {
  margin: 1rem 0 0 0 !Important;
}
.container h3 {
  margin: 1rem 0 0 0 !Important;
}
.card {
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
}
.card:hover {
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
}