.FarmRentOffer {
  padding-top: 15px;
}

.FarmRentOffer form {
  padding-bottom: 15px;
}

.FarmRentOffer .card-img-top {
  width: auto;
  max-height: 600px;
  background-repeat: no-repeat;
  background-size: contain;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.FarmRentOffer form text {
  font-size: 24px;
}

.FarmRentOffer form textarea {
  height: 200px;
  font-size: 14px;
}

.FarmRentOffer .ck-file-dialog-button {
  display: none;
  visibility: hidden;
}

.FarmRentOffer .Countdown {
  color: #fc4a1a;
}

.FarmRentOffer .offer-col {
  background-color: #7ebc59;
}

.FarmRentOffer .text-muted {
  color: #33363b !important;
}
