.Login form {
  margin: 0;
}

.Login .msg-center {
  margin: 0;
}

.Login form span.help-block {
  font-size: 14px;
  padding-bottom: 10px;
  color: #999;
}

@media all and (min-width: 480px) {
  .Login {
    padding: 15px 0;
  }

  .Login form {
    margin: 0 auto;
    max-width: 320px;
  }

  .Login .msg-center {
    margin: 0 auto;
    max-width: 320px;
  }
}
