.ForRent {
  padding-top: 15px;
}

.ForRent .float-right {
  float: right !important;
}

.ForRent .pageImage {
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-top: 15px;
}

.ForRent .bold {
  font-weight: 600;
}
