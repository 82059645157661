.ForSale {
  padding-top: 15px;
}

.ForSale .float-right {
  float: right !important;
}

.ForSale .pageImage {
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-top: 15px;
}

.ForSale .bold {
  font-weight: 600;
}
