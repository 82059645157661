.OrgEdit {
  padding-top: 15px;
}

.OrgEdit form {
  padding-bottom: 15px;
}

.OrgEdit form text {
  font-size: 24px;
}

.OrgEdit form textarea {
  height: 200px;
  font-size: 14px;
}

.OrgEdit .ck-file-dialog-button {
  display: none;
  visibility: hidden;
}

.OrgEdit .card-text {
  font-size: 75%;
}

.OrgEdit .card-img-top {
  width: auto;
  max-height: 160px;
  background-repeat: no-repeat;
  background-size: contain;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.OrgEdit .card-footer-btn {
  margin-left: 2px;
}
