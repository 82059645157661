.Auctions {
  padding-top: 15px;
}

.Auctions .float-right {
  float: right !important;
}

.Auctions .pageImage {
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-top: 15px;
}

.Auctions .bold {
  font-weight: 600;
}
