.AboutUs .pageImage {
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-top: 15px;
}

.AboutUs .text-center {
  text-align: center;
}
